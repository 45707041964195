<template>
  <div class="rs-report" :class="pdfType ? `is-pdf-${pdfType}` : ''">
    <div v-if="loading" class="d-flex flex-column align-center justify-center mt-12">
      <h3 class="mb-8">Loading your Risk Score...</h3>
      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </div>
    <template v-if="!loading && !report">
      <v-container class="mt-7">
        <v-row class="align-center">
          <v-col class="text-center">
            <v-card
              v-if="message"
              outlined
              max-width="600"
              elevation="12"
              class="mx-auto py-8"
            >
              <v-card-text>
                <h3>
                  {{ message }}
                </h3>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-if="!loading && report">
      <v-system-bar
        color="rgba(255, 255, 255, 0.1)"
        class="mb-2 py-4 text-right no-print"
      >
        <v-container>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }"
              ><v-btn icon v-bind="attrs" v-on="on" class="mr-4">
                <v-icon
                  id="rs_report_pdf_download"
                  color="white"
                  title="Download"
                  :data-prospect="reportInput.prospect.id"
                  @click="handlePdf('download')"
                  >mdi-download-circle</v-icon
                >
              </v-btn>
            </template>
            <span>Download PDF</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }"
              ><v-btn id="rs_report_pdf_print" icon v-bind="attrs" v-on="on">
                <v-icon
                  color="white"
                  title="Print"
                  class="mr-0"
                  id="rs_report_pdf_print"
                  :data-prospect="reportInput.prospect.id"
                  @click="handlePdf('print')"
                  >mdi-printer</v-icon
                >
              </v-btn>
            </template>
            <span>Print</span>
          </v-tooltip>
        </v-container>
      </v-system-bar>
      <v-container>
        <ReportTemplateHeader
          :reportVersion="report._version"
          :reportDate="report._generated"
          :prospectQualified="reportInput.prospect.qualified"
          :prospectName="prospectName"
          :advisor="reportInput.advisor"
          :pdfType="pdfType"
        />
        <ReportTemplateContent
          :advisor="reportInput.advisor"
          :prospectId="reportInput.prospect.id"
          :prospectOrigin="reportInput.prospect.origin"
          :score="reportInput.score"
          :averageScore="reportInput.average_score_for_age"
          :age="new Date().getFullYear() - reportInput.year_born"
          :gender="reportInput.gender"
          :pdfType="pdfType"
        >
          <template #mainTitle>
            <html-fragment
              :html="sanitizeContent(reportTemplate.mainTitle)"
            ></html-fragment>
          </template>
          <template #likelihood
            ><html-fragment
              :html="sanitizeContent(reportTemplate.likelihood)"
            ></html-fragment
          ></template>
          <template #factorsTitle>
            <html-fragment
              :html="sanitizeContent(reportTemplate.factorsTitle)"
            ></html-fragment
          ></template>
          <template #factorsBoxes>
            <v-col
              v-for="(box, index) in reportTemplate.factorsBoxes"
              :key="box.title + index"
              class="d-flex"
              :class="[
                index !== 2 ? 'col-sm-6' : '',
                pdfType ? 'col-4' : 'col-12 col-lg-4 ',
              ]"
            >
              <v-card
                elevation="8"
                class="d-flex flex-column text-center flex-grow-1"
                :class="pdfType === 'download' ? 'white' : ''"
              >
                <v-card-title
                  class="justify-center font-weight-bold"
                  :class="[
                    pdfType === 'download' ? 'black--text' : '',
                    pdfType ? 'rs-report__tertiary-title' : 'text-h6',
                  ]"
                >
                  <html-fragment :html="sanitizeContent(box.title)"></html-fragment>
                </v-card-title>
                <div class="px-4" :class="pdfType === 'download' ? 'black--text' : ''">
                  <html-fragment :html="sanitizeContent(box.text)"></html-fragment>
                </div>
              </v-card>
            </v-col>
          </template>
          <template #howMuchLoseTitle>
            <html-fragment
              :html="sanitizeContent(reportTemplate.howMuchLoseTitle)"
            ></html-fragment
          ></template>
          <template #howMuchLoseText>
            <html-fragment
              :html="sanitizeContent(reportTemplate.howMuchLoseText)"
            ></html-fragment
          ></template>
          <template #yourChoiceText
            ><html-fragment
              :html="sanitizeContent(reportTemplate.yourChoiceText)"
            ></html-fragment
          ></template>
          <template #ctaText
            ><html-fragment
              :html="sanitizeContent(reportTemplate.ctaText)"
            ></html-fragment
          ></template>
          <template #extraContent
            ><html-fragment
              :html="sanitizeContent(reportTemplate.extraContent)"
            ></html-fragment
          ></template>
          <template #specificAdvisorDisclosure
            ><html-fragment
              v-if="reportInput.advisor"
              :html="sanitizeContent(reportInput.advisor.disclosure)"
            ></html-fragment
          ></template>
          <template #genericAdvisorDisclosure
            ><html-fragment
              :html="sanitizeContent(reportTemplate.genericAdvisorDisclosure)"
            ></html-fragment
          ></template>
          <template #howMuchLoseGraphic>
            <ReportHowMuchLoseAnimation
              :lossValue="reportInput.social_security_cut_info.lifetime_benefit_cut"
              :benefitValue="
                reportInput.social_security_cut_info
                  .promised_lifetime_total_benefits_post_reduction
              "
              :lossTextContent="
                reportTemplate.howMuchLoseGraphic
                  ? reportTemplate.howMuchLoseGraphic.lossText
                  : ''
              "
              :benefitTextContent="
                reportTemplate.howMuchLoseGraphic
                  ? reportTemplate.howMuchLoseGraphic.benefitText
                  : ''
              "
              :pdfType="pdfType"
            />
          </template>
        </ReportTemplateContent>
      </v-container>
    </template>

    <v-dialog v-model="dialogPdf" persistent width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <div class="d-flex flex-column align-center justify-center my-12">
              <h3 v-if="dialogPdfErrorText">{{ dialogPdfErrorText }}</h3>
              <h3 v-else class="mb-8">
                Please wait while your {{ dialogPdfTypeText }} PDF is generated...
              </h3>
              <v-btn
                v-if="dialogPdfErrorText"
                class="mt-8"
                color="error"
                @click="dialogPdf = false"
                >Close</v-btn
              >
              <v-progress-circular
                v-else
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DOMPurify from "dompurify";
import HtmlFragment from "@/components/FunctionalHtmlReplace";
import { objectIsEmpty } from "@/helpers";

import { mask } from "maska";

import getUserCredentialsMixin from "@/mixins/getUserCredentialsMixin";

import ReportHowMuchLoseAnimation from "@/components/ReportHowMuchLoseAnimation.vue";
import ReportTemplateHeader from "@/components/ReportTemplateHeader";
import ReportTemplateContent from "@/components/ReportTemplateContent";

export default {
  mixins: [getUserCredentialsMixin],
  components: {
    HtmlFragment,
    ReportHowMuchLoseAnimation,
    ReportTemplateHeader,
    ReportTemplateContent,
  },
  data() {
    return {
      loading: false,
      report: null,
      reportTemplate: null,
      // prospect: null,
      prospectId: null,
      message: "",
      pdfType: null,
      dialogPdf: false,
      dialogPdfTypeText: "",
      dialogPdfErrorText: "",
    };
  },
  async beforeRouteEnter(to, from, next) {
    // react to route changes...
    next((vm) => {
      // console.log("VM ---> ", to);
      // access to component's instance using `vm` .
      // this is done because this navigation guard is called before the component is created.
      // clear your previous data.
      // re-populate data
      if (to.query.pdfType) {
        vm.pdfType = to.query.pdfType;
      }

      vm.getCredentials(to.params.id);
      vm.prospectId = to.params.id;
      // vm.advisorVanityName = to.params.advisorVanityName
    });
  },
  async beforeRouteUpdate(to) {
    // react to route changes...
    // this.getData(to.params.id);
    this.getCredentials(to.params.id);
  },
  mounted() {
    this.getData();
  },
  computed: {
    showAdvisorInfo() {
      // Only show advisor info if there is an advisor and the current prospect is qualified
      if (this.report.data.advisor && this.report.data.prospect.qualified) {
        return true;
      } else {
        return false;
      }
    },
    prospectName() {
      let firstName = null;
      let lastName = null;
      let fullName = null;

      if (this.reportInput.prospect) {
        if (this.reportInput.prospect.first_name) {
          firstName = this.reportInput.prospect.first_name;

          if (this.reportInput.prospect.last_name) {
            lastName = this.reportInput.prospect.last_name;
          }

          fullName = `${firstName ? firstName : ""} ${lastName ? lastName : ""}`;
        }
      }
      return fullName;
    },
  },
  methods: {
    formatPhone(phone) {
      if (!phone) {
        return "";
      }
      return mask(phone, "###-###-####");
    },
    async getData() {
      const prospectId = this.prospectId;

      if (!prospectId) {
        this.message =
          "Sorry, the score report you are trying to reach is invalid. Please contact your advisor for assistance.";
        return;
      } else {
        this.fetchReport(prospectId);
      }
    },
    fetchReport(prospectId) {
      // Fetch score by submitting scoring params
      this.loading = true;

      // Add route path to data for api use - ex: your-score-report
      // const reportRoutePath = this.$route.path.split("/")[1];
      // data.reportRoutePath = reportRoutePath;

      fetch(process.env.VUE_APP_SCORE_API + "score_report_v3", {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prospectId),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong fetching report", response);
          }
        })
        .then((data) => {
          if (data.success) {
            // console.log("data report", data);
            if (!data.report || data.report.template.length < 1) {
              this.message =
                "Sorry, we were unable to locate your report. Please refresh to try again or contact your advisor for assistance.";
            } else {
              this.report = data.report;
              this.reportInput = data.input;
              this.reportTemplate = this.report.template[0].content;

              console.log("reportInput.advisor", this.reportInput.advisor);
            }
          } else {
            console.log("Error data:", data);
            throw new Error(data.message);
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (!this.report) {
            this.message = error.message;
          }
        });
    },
    checkObjectEmpty(obj) {
      return objectIsEmpty(obj);
    },
    sanitizeContent(html) {
      const sanitized = DOMPurify.sanitize(html, {
        ALLOWED_TAGS: [
          "a",
          "b",
          "i",
          "p",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "em",
          "u",
          "span",
          "strong",
        ],
        ALLOWED_ATTR: ["class", "style", "href", "target", "rel"],
      });
      return sanitized;
    },
    async handlePdf(pdfType) {
      this.dialogPdfErrorText = "";
      this.dialogPdfTypeText = pdfType === "print" ? "print-ready" : "downloadable";
      this.dialogPdf = true;

      try {
        const response = await fetch(
          `${process.env.VUE_APP_PDF_API}?report_id=${this.prospectId}&pdfType=${pdfType}`
        );

        const data = await response.json();

        if (!data.pdfUrl) {
          throw Error("Sorry, your PDF could not be generated. Please try again.");
        }
        this.dialogPdfTypeText = "";
        this.dialogPdf = false;

        window.open(data.pdfUrl, "_blank");
      } catch (error) {
        console.log(error);
        this.dialogPdfErrorText = error;
      }
    },
  },
};
</script>
