var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rs-report",class:_vm.pdfType ? ("is-pdf-" + _vm.pdfType) : ''},[(_vm.loading)?_c('div',{staticClass:"d-flex flex-column align-center justify-center mt-12"},[_c('h3',{staticClass:"mb-8"},[_vm._v("Loading your Risk Score...")]),_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"white","indeterminate":""}})],1):_vm._e(),(!_vm.loading && !_vm.report)?[_c('v-container',{staticClass:"mt-7"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"text-center"},[(_vm.message)?_c('v-card',{staticClass:"mx-auto py-8",attrs:{"outlined":"","max-width":"600","elevation":"12"}},[_c('v-card-text',[_c('h3',[_vm._v(" "+_vm._s(_vm.message)+" ")])])],1):_vm._e()],1)],1)],1)]:_vm._e(),(!_vm.loading && _vm.report)?[_c('v-system-bar',{staticClass:"mb-2 py-4 text-right no-print",attrs:{"color":"rgba(255, 255, 255, 0.1)"}},[_c('v-container',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"id":"rs_report_pdf_download","color":"white","title":"Download","data-prospect":_vm.reportInput.prospect.id},on:{"click":function($event){return _vm.handlePdf('download')}}},[_vm._v("mdi-download-circle")])],1)]}}],null,false,3237072256)},[_c('span',[_vm._v("Download PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"rs_report_pdf_print","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-0",attrs:{"color":"white","title":"Print","id":"rs_report_pdf_print","data-prospect":_vm.reportInput.prospect.id},on:{"click":function($event){return _vm.handlePdf('print')}}},[_vm._v("mdi-printer")])],1)]}}],null,false,1350597924)},[_c('span',[_vm._v("Print")])])],1)],1),_c('v-container',[_c('ReportTemplateHeader',{attrs:{"reportVersion":_vm.report._version,"reportDate":_vm.report._generated,"prospectQualified":_vm.reportInput.prospect.qualified,"prospectName":_vm.prospectName,"advisor":_vm.reportInput.advisor,"pdfType":_vm.pdfType}}),_c('ReportTemplateContent',{attrs:{"advisor":_vm.reportInput.advisor,"prospectId":_vm.reportInput.prospect.id,"prospectOrigin":_vm.reportInput.prospect.origin,"score":_vm.reportInput.score,"averageScore":_vm.reportInput.average_score_for_age,"age":new Date().getFullYear() - _vm.reportInput.year_born,"gender":_vm.reportInput.gender,"pdfType":_vm.pdfType},scopedSlots:_vm._u([{key:"mainTitle",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.mainTitle)}})]},proxy:true},{key:"likelihood",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.likelihood)}})]},proxy:true},{key:"factorsTitle",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.factorsTitle)}})]},proxy:true},{key:"factorsBoxes",fn:function(){return _vm._l((_vm.reportTemplate.factorsBoxes),function(box,index){return _c('v-col',{key:box.title + index,staticClass:"d-flex",class:[
              index !== 2 ? 'col-sm-6' : '',
              _vm.pdfType ? 'col-4' : 'col-12 col-lg-4 ' ]},[_c('v-card',{staticClass:"d-flex flex-column text-center flex-grow-1",class:_vm.pdfType === 'download' ? 'white' : '',attrs:{"elevation":"8"}},[_c('v-card-title',{staticClass:"justify-center font-weight-bold",class:[
                  _vm.pdfType === 'download' ? 'black--text' : '',
                  _vm.pdfType ? 'rs-report__tertiary-title' : 'text-h6' ]},[_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(box.title)}})],1),_c('div',{staticClass:"px-4",class:_vm.pdfType === 'download' ? 'black--text' : ''},[_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(box.text)}})],1)],1)],1)})},proxy:true},{key:"howMuchLoseTitle",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.howMuchLoseTitle)}})]},proxy:true},{key:"howMuchLoseText",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.howMuchLoseText)}})]},proxy:true},{key:"yourChoiceText",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.yourChoiceText)}})]},proxy:true},{key:"ctaText",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.ctaText)}})]},proxy:true},{key:"extraContent",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.extraContent)}})]},proxy:true},{key:"specificAdvisorDisclosure",fn:function(){return [(_vm.reportInput.advisor)?_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportInput.advisor.disclosure)}}):_vm._e()]},proxy:true},{key:"genericAdvisorDisclosure",fn:function(){return [_c('html-fragment',{attrs:{"html":_vm.sanitizeContent(_vm.reportTemplate.genericAdvisorDisclosure)}})]},proxy:true},{key:"howMuchLoseGraphic",fn:function(){return [_c('ReportHowMuchLoseAnimation',{attrs:{"lossValue":_vm.reportInput.social_security_cut_info.lifetime_benefit_cut,"benefitValue":_vm.reportInput.social_security_cut_info
                .promised_lifetime_total_benefits_post_reduction,"lossTextContent":_vm.reportTemplate.howMuchLoseGraphic
                ? _vm.reportTemplate.howMuchLoseGraphic.lossText
                : '',"benefitTextContent":_vm.reportTemplate.howMuchLoseGraphic
                ? _vm.reportTemplate.howMuchLoseGraphic.benefitText
                : '',"pdfType":_vm.pdfType}})]},proxy:true}],null,false,1885863763)})],1)]:_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"600px"},model:{value:(_vm.dialogPdf),callback:function ($$v) {_vm.dialogPdf=$$v},expression:"dialogPdf"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('div',{staticClass:"d-flex flex-column align-center justify-center my-12"},[(_vm.dialogPdfErrorText)?_c('h3',[_vm._v(_vm._s(_vm.dialogPdfErrorText))]):_c('h3',{staticClass:"mb-8"},[_vm._v(" Please wait while your "+_vm._s(_vm.dialogPdfTypeText)+" PDF is generated... ")]),(_vm.dialogPdfErrorText)?_c('v-btn',{staticClass:"mt-8",attrs:{"color":"error"},on:{"click":function($event){_vm.dialogPdf = false}}},[_vm._v("Close")]):_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1)])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }